.ui.header {
  font-family: 'Futura' !important;
}

h1.ui.header {
  font-weight: 300 !important;
  font-size: 3.2rem !important;
}

.ui.massive.menu {
  font-size: 1.7rem !important;
}

.ui.placeholder.segment .button {
  max-width: max-content !important;
}

.ui.segment.item {
  position: relative;
  background: #fff;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 1rem 0;
  padding: 1em;
  border-radius: 0.25rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.blue.segment:not(.inverted) {
  border-top: 2px solid #2E4F8B!important;
}

.primary {
  background-color: #2E4F8B !important;
}

.primary:hover {
  opacity: 0.7 !important;
}

.background-blue {
  background-color: #2E4F8B !important;
  color: rgba(255,255,255,.9) !important;
}