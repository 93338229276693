
/* Relevant styles */

.iphone-iframe {
  width: 100%;
  height: 100%;
}

.macbook-air {
  display: inline-block;
  position: relative;
  text-align: left;
  width: 133.35em;
  height: 91em;
  font-size: 6px;
  box-sizing: border-box;
  border-radius: 5.17em;
  border-bottom-left-radius: 3em;
  border-bottom-right-radius: 3em;
  margin: 0 15em 4em 15em;
  padding: 6.67em 6.67em 9.34em 6.67em;
  box-shadow: 	0 2em 1em -2em, 
				inset 0 0 0.17em #666, 
				inset 0 0 0 0.5em #fafafb, 
				inset 0 0 0.17em 0.67em rgba(255, 255, 255, 0.4), 
				inset 0 -15em 16.67em -16.67em rgba(0, 0, 0, 0.5),
				inset 0 -2em 0.5em -1em #aaa,				
				inset 0 0 0 1em #252525, 
				inset 0 0 0 1.17em rgba(255, 255, 255, 0.4), 
				inset 50em 50em 33.34em -58.34em rgba(0, 0, 0, 0.1),
				inset 50em 50em 50em -50em rgba(0, 0, 0, 0.2), 
				inset -33.34em -41.67em 33.34em -50em #c5c4c9,
				inset 0 6.17em 0.17em #efeff2,
				inset 6.17em 0 0.17em #efeff2,
				inset -6.17em 0 0.17em #efeff2,
				inset 0 -8.67em 0.17em #efeff2,
				inset 0 6.5em white,
				inset 6.5em 0 white,				
				inset -6.5em -6.5em white,
				inset 0 -9.1em white,
				inset 0 6.67em #888,
				inset 6.67em 0 #888,
				inset -6.66em -6.67em #888,
				inset 0 -9.17em #888, 
				inset 0 0 0 166.67em #222,
				0 0 0.17em black;

}

.macbook-air::before {
  content: 'MacBook';
  font-family: MyriadPro-Regular, 'Myriad Pro Regular', MyriadPro, 'Myriad Pro', 'Trebuchet MS', sans-serif;
  font-weight: 600;
  font-size: 2.4em;
  text-shadow: 31.39em -2.3em #444;
  color: transparent;
  position: absolute;
  bottom: -1.12em;
  left: -6.25em;
  width: 67.99em;
  height: 1.53em;
  border-bottom-left-radius: 13.89em 1.39em;
  border-bottom-right-radius: 13.89em 1.39em;
  box-shadow: 	inset -0.42em 0 0.42em -0.42em rgba(0, 0, 0, 0.7), 
				inset 0.42em 0 0.42em -0.42em rgba(0, 0, 0, 0.7), 
				inset 0 6.81em 0.42em -6.95em rgba(0, 0, 0, 0.7), 
				inset 0 7.16em 0.14em -6.95em rgba(255, 255, 255, 0.9), 
				inset 0 -694.87em 1.67em -694.45em black, 
				inset 0em -0.91em 0.21em #abaab0, 
				inset 27.92em 0 0.07em #abaab0, 
				inset 27.92em -0.07em #ddd, 
				inset -27.92em 0 #abaab0, 
				inset -27.92em -0.07em #ddd, 
				inset 0em -0.98em 0 #ddd, 
				inset 0em -1.05em #bebbc5, 
				inset 28.34em -0.07em 0.42em rgba(0, 0, 0, 0.5), 
				inset 27.92em 0 0.42em rgba(0, 0, 0, 0.4), 
				inset -28.34em -0.07em 0.42em rgba(0, 0, 0, 0.5), 
				inset -27.92em 0 0.42em rgba(0, 0, 0, 0.4), 
				0 0 0.07em black,
				0 0.77em 0.42em -0.7em black,
				inset 0 1.5em white;
}

.macbook-air::after {
  content: 'Air';
  white-space: nowrap;
  font-family: MyriadPro-Regular, 'Myriad Pro Regular', MyriadPro, 'Myriad Pro', Verdana, sans-serif;
  color: #444;
  font-size: 2.4em;
  position: absolute;
  bottom: 1.88em;
  left: 53%;
  width: 0.84em;
  height: 0.84em;
  border-radius: 100%;
  box-shadow: 	-2.02em -33.89em 0 -0.35em rgba(255, 255, 255, 0.5), 
				-2.09em -34.03em 0 -0.28em #333, 
				-2.09em -34.03em 0 -0.14em #444, 
				-2.09em -34.03em 0 -0.07em #222, 
				-2.09em -34.03em 0.07em rgba(255, 255, 255, 0.5),
				-13.69em 2.78em 0 -0.35em #444,
				-13.6em 2.78em 0 -0.35em #444,
				-13.55em 2.78em 0 -0.35em #444,
				-13.5em 2.78em 0 -0.35em #444,
				-13.41em 2.78em 0 -0.35em #444,
				-13.35em 2.78em 0 -0.35em #444,
				-13.27em 2.78em 0 -0.35em #444,
				8.69em 2.78em 0 -0.35em #444,
				8.75em 2.78em 0 -0.35em #444,
				8.82em 2.78em 0 -0.35em #444,
				8.88em 2.78em 0 -0.35em #444,
				8.96em 2.78em 0 -0.35em #444,
				9.05em 2.78em 0 -0.35em #444,
				9.1em 2.78em 0 -0.35em #444;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.macbook-air {
		border-radius: 4.84em;
		border-bottom-left-radius: 3em;
		border-bottom-right-radius: 3em;
		box-shadow: 0 2em 1em -2em, 
					inset 0 0 0.17em #666, 
					inset 0.5em 0.5em #fafafb, 
					inset -0.5em 0.5em #fafafb, 
					inset 0.5em -0.5em #fafafb, 
					inset -0.5em -0.5em #fafafb, 
					inset 0.67em 0.67em 0.17em rgba(255, 255, 255, 0.4), 
					inset -0.67em 0.67em 0.17em rgba(255, 255, 255, 0.4), 
					inset 0.67em -0.67em 0.17em rgba(255, 255, 255, 0.4), 
					inset -0.67em -0.67em 0.17em rgba(255, 255, 255, 0.4), 
					inset 0 -15em 16.67em -16.67em rgba(0, 0, 0, 0.5),
					inset 0 -2em 0.5em -1em #aaa,
					inset 1em 1em #252525, 
					inset -1em 1em #252525, 
					inset 1em -1em #252525, 
					inset -1em -1em #252525, 
					inset 1.17em 1.17em rgba(255, 255, 255, 0.4), 
					inset -1.17em 1.17em rgba(255, 255, 255, 0.4), 
					inset 1.17em -1.17em rgba(255, 255, 255, 0.4), 
					inset -1.17em -1.17em rgba(255, 255, 255, 0.4), 
					inset 50em 50em 33.34em -58.34em rgba(0, 0, 0, 0.1),
					inset 50em 50em 50em -50em rgba(0, 0, 0, 0.2), 
					inset -33.34em -41.67em 33.34em -50em #c5c4c9,
					inset 0 6.17em 0.17em #efeff2,
					inset 6.17em 0 0.17em #efeff2,
					inset -6.17em 0 0.17em #efeff2,
					inset 0 -8.67em 0.17em #efeff2,
					inset 0 6.5em white,
					inset 6.5em 0 white,				
					inset -6.5em -6.5em white,
					inset 0 -9.2em white,
					inset 0 6.67em #888,
					inset 6.67em 0 #888,
					inset -6.67em -6.67em #888,
					inset 0 -9.3em #888, 
					inset 0 0 0 166.67em #222,
					0 0 0.17em black;
	}
}

@-moz-document url-prefix() {
	.macbook-air::before {
	  box-shadow: 	inset -0.42em 0 0.42em -0.42em rgba(0, 0, 0, 0.4), 
					inset 0.42em 0 0.42em -0.42em rgba(0, 0, 0, 0.4), 
					inset 0 6.81em 0.42em -6.95em rgba(0, 0, 0, 0.4), 
					inset 0 7.16em 0.14em -6.95em rgba(255, 255, 255, 0.95), 
					inset 0 -694.87em 1.39em -694.45em black, 
					inset 0em -0.91em 0.21em #abaab0, 
					inset 27.92em 0 0.07em #abaab0, 
					inset 27.92em -0.07em #ddd, 
					inset -27.92em 0 #abaab0, 
					inset -27.92em -0.07em #ddd, 
					inset 0em -0.98em 0 #ddd, 
					inset 0em -1.05em #BEBBC5, 
					inset 28.34em -0.07em 0.42em rgba(0, 0, 0, 0.5), 
					inset 27.92em 0 0.42em rgba(0, 0, 0, 0.4), 
					inset -28.34em -0.07em 0.42em rgba(0, 0, 0, 0.5), 
					inset -27.92em 0 0.42em rgba(0, 0, 0, 0.4),
					0 0 0.07em black,
					0 0.98em 0.42em -0.35em rgba(0, 0, 0, 0.2),
					inset 0 0 0 69.45em white;
	}
}

.iphone-6 {
  display: inline-block;
  font-size: 6px;
  margin: 3em;
  position: relative;
  width: 62.5em;
  height: 111.167em;
  padding: 0.334em;
  box-sizing: content-box;
  border-radius: 9.5em;
}

.iphone-6::before {
  content: '';
  display: block;
  position: absolute;
  height: 3em;
  width: 1em;
  top: -6em;
  left: -4.333em;
  border-radius: 20%;
}

.iphone-6::after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 100%;
  height: 2em;
  width: 2em;
  top: -13.333em;
  left: calc(50% - 1em);
}

.iphone-6.black{
  border-top: 16em solid #151515;
  border-bottom: 14.5em solid #151515;
  border-left: 2.5em solid #151515;
  border-right: 2.5em solid #151515;
  background: #222;
  box-shadow: 0 -2em 0.667em -2em #222,
			  0 2em 0.667em -1em rgba(20, 20, 20, 0.7),
			  1.333em 0 0.667em -1em #111,
			  0 0 0 0.667em #444,
			  0 0 0 1em #252525, 
			  0 0 1.667em 0.667em rgba(252, 252, 252, 0.5), 
			  0 0 1.667em 0.667em #fcfcfc,
			  0 0 0 2em #445,
			  0 0 5.333em 2em rgba(0, 0, 0, 0.1), 
			  inset 0 0 0 0.334em #333;
}

.iphone-6.black::before {
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 120em rgba(0, 0, 0, 0.3),
			  71em 0 rgba(0, 0, 0, 0.3), 
			  71em 120em rgba(0, 0, 0, 0.3),			  
			  -1em 6.667em 0em -0.333em #667, 
			  -1em 8em 0em -0.333em #667, 
			  -1em 10.333em 0em -0.333em #667,
			  -1.333em 7em 0em -0.333em #778, 
			  -1.333em 8em 0em -0.333em #778, 
			  -1.333em 10em 0em -0.333em #778,			  
			  -1em 7em #444, 
			  -1em 8em #444, 
			  -1em 10em #444, 			  
			  -1em 19.667em 0em -0.333em #667, 
			  -1em 21em 0em -0.333em #667, 
			  -1em 23em 0em -0.333em #667, 
			  -1em 25em 0em -0.333em #667, 
			  -1em 27em 0em -0.333em #667, 
			  -1em 28.333em 0em -0.333em #667, 
			  -1.333em 20em 0em -0.333em #778, 
			  -1.333em 21em 0em -0.333em #778, 
			  -1.333em 23em 0em -0.333em #778, 
			  -1.333em 24em 0em -0.333em #778, 
			  -1.333em 26em 0em -0.333em #778, 
			  -1.333em 28em 0em -0.333em #778, 
			  -1em 20em #444, 
			  -1em 21em #444, 
			  -1em 23em #444, 
			  -1em 24em #444, 
			  -1em 26em #444, 
			  -1em 28em #444, 			  
			  -1em 33em 0em -0.333em #667, 
			  -1em 34em 0em -0.333em #667, 
			  -1em 36em 0em -0.333em #667, 
			  -1em 37em 0em -0.333em #667, 
			  -1em 39em 0em -0.333em #667, 
			  -1em 41.333em 0em -0.333em #667, 			  
			  -1.333em 33.333em 0em -0.333em #778, 
			  -1.333em 34em 0em -0.333em #778, 
			  -1.333em 36em 0em -0.333em #778, 
			  -1.333em 37em 0em -0.333em #778, 
			  -1.333em 39em 0em -0.333em #778, 
			  -1.333em 41em 0em -0.333em #778,			  
			  -1em 33.333em #444, 
			  -1em 34em #444, 
			  -1em 36em #444, 
			  -1em 37em #444, 
			  -1em 39em #444, 
			  -1em 41em #444, 			  
			  71.667em 19.667em 0em -0.333em #667, 
			  71.667em 21em 0em -0.333em #667, 
			  71.667em 23em 0em -0.333em #667, 
			  71.667em 25em 0em -0.333em #667, 
			  71.667em 27em 0em -0.333em #667, 
			  71.667em 28.333em 0em -0.333em #667, 
			  72em 20em 0em -0.333em #778, 
			  72em 21em 0em -0.333em #778, 
			  72em 23em 0em -0.333em #778, 
			  72em 24em 0em -0.333em #778, 
			  72em 26em 0em -0.333em #778, 
			  72em 28em 0em -0.333em #778, 
			  71.667em 20em #444, 
			  71.667em 21em #444, 
			  71.667em 23em #444, 
			  71.667em 24em #444, 
			  71.667em 26em #444, 
			  71.667em 28em #444;
}

.iphone-6.black::after {
  box-shadow: inset 0 0 0 0.334em #1b1b1b,
			  -11.667em 5em 0 -0.5em rgba(50, 50, 255, 0.4),
			  -11em 5.333em 1em -0.5em rgba(255, 255, 255, 0.3),
			  -11.333em 5em 0.333em -0.333em #222,
			  -11.333em 5em 0 0 #252525,
			  -11.333em 5em 0 0.333em #333, 
			  -5.333em 5em 0 -0.333em #252525, 
			  -5em 5em 0 -0.333em #252525, 
			  -4.667em 5em 0 -0.333em #252525, 
			  -4.333em 5em 0 -0.333em #252525, 
			  -4em 5em 0 -0.333em #252525, 
			  -3.667em 5em 0 -0.333em #252525, 
			  -3.333em 5em 0 -0.333em #252525, 
			  -3em 5em 0 -0.333em #252525, 
			  -2.667em 5em 0 -0.333em #252525, 
			  -2.333em 5em 0 -0.333em #252525, 
			  -2em 5em 0 -0.333em #252525, 
			  -1.667em 5em 0 -0.333em #252525, 
			  -1.333em 5em 0 -0.333em #252525, 
			  -1em 5em 0 -0.333em #252525, 
			  -0.667em 5em 0 -0.333em #252525, 
			  -0.333em 5em 0 -0.333em #252525, 
			  0 5em 0 -0.333em #252525, 
			  0.333em 5em 0 -0.333em #252525, 
			  0.667em 5em 0 -0.333em #252525, 
			  1em 5em 0 -0.333em #252525, 
			  1.333em 5em 0 -0.333em #252525, 
			  1.667em 5em 0 -0.333em #252525, 
			  2em 5em 0 -0.333em #252525, 
			  2.333em 5em 0 -0.333em #252525, 
			  2.667em 5em 0 -0.333em #252525, 
			  3em 5em 0 -0.333em #252525, 
			  3.333em 5em 0 -0.333em #252525, 
			  3.667em 5em 0 -0.333em #252525,
			  4em 5em 0 -0.333em #252525, 
			  4.333em 5em 0 -0.333em #252525, 
			  4.667em 5em 0 -0.333em #252525, 
			  5em 5em 0 -0.333em #252525, 
			  5.333em 5em 0 -0.333em #252525, 
			  0 132em 2em 3em #1c1c1c, 
			  0 132em 0 4em #151515, 
			  -2em 132em 2em 2.75em #151515, 
			  3em 132em 2em 2.75em #151515,
			  0 132em 0 4.75em rgba(95, 95, 95, 0.4), 
			  3em 129em 0 3em #151515, 
			  0 126em 0 0em #151515, 
			  -3em 129em 0 3em #151515,
			  0 132em 0 4.75em #777;
}

.iphone-6.black.landscape {
  border-left: 16em solid #151515;
  border-right: 14.5em solid #151515;
  border-bottom: 2.5em solid #151515;
  border-top: 2.5em solid #151515;
  width: 111.167em;
  height: 62.5em;
}

.iphone-6.black.landscape::before {
  height: 1em;
  width: 3em;
  left: -6em;
  top: -4.333em;
  box-shadow: 120em 0 rgba(0, 0, 0, 0.3),
			  0 71em rgba(0, 0, 0, 0.3), 
			  120em 71em rgba(0, 0, 0, 0.3),			  
			  6.667em 71.667em 0em -0.333em #667, 
			  8em 71.667em 0em -0.333em #667, 
			  10.333em 71.667em 0em -0.333em #667,
			  7em 72em 0em -0.333em #778, 
			  8em 72em 0em -0.333em #778, 
			  10em 72em 0em -0.333em #778,			  
			  7em 71.667em #444, 
			  8em 71.667em #444, 
			  10em 71.667em #444, 			  
			  19.667em -1em 0em -0.333em #667, 
			  21em -1em 0em -0.333em #667, 
			  23em -1em 0em -0.333em #667, 
			  25em -1em 0em -0.333em #667, 
			  27em -1em 0em -0.333em #667, 
			  28.333em -1em 0em -0.333em #667, 
			  20em -1.333em 0em -0.333em #778, 
			  21em -1.333em 0em -0.333em #778, 
			  23em -1.333em 0em -0.333em #778, 
			  24em -1.333em 0em -0.333em #778, 
			  26em -1.333em 0em -0.333em #778, 
			  28em -1.333em 0em -0.333em #778, 
			  20em -1em #444, 
			  21em -1em #444, 
			  23em -1em #444, 
			  24em -1em #444, 
			  26em -1em #444, 
			  28em -1em #444, 			  
			  33em 71.667em 0em -0.333em #667, 
			  34em 71.667em 0em -0.333em #667, 
			  36em 71.667em 0em -0.333em #667, 
			  37em 71.667em 0em -0.333em #667, 
			  39em 71.667em 0em -0.333em #667, 
			  41.333em 71.667em 0em -0.333em #667, 			  
			  33.333em 72em 0em -0.333em #778, 
			  34em 72em 0em -0.333em #778, 
			  36em 72em 0em -0.333em #778, 
			  37em 72em 0em -0.333em #778, 
			  39em 72em 0em -0.333em #778, 
			  41em 72em 0em -0.333em #778,			  
			  33.333em 71.667em #444, 
			  34em 71.667em #444, 
			  36em 71.667em #444, 
			  37em 71.667em #444, 
			  39em 71.667em #444, 
			  41em 71.667em #444, 			  
			  19.667em 71.667em 0em -0.333em #667, 
			  21em 71.667em 0em -0.333em #667, 
			  23em 71.667em 0em -0.333em #667, 
			  25em 71.667em 0em -0.333em #667, 
			  27em 71.667em 0em -0.333em #667, 
			  28.333em 71.667em 0em -0.333em #667, 
			  20em 72em 0em -0.333em #778, 
			  21em 72em 0em -0.333em #778, 
			  23em 72em 0em -0.333em #778, 
			  24em 72em 0em -0.333em #778, 
			  26em 72em 0em -0.333em #778, 
			  28em 72em 0em -0.333em #778, 
			  20em 71.667em #444, 
			  21em 71.667em #444, 
			  23em 71.667em #444, 
			  24em 71.667em #444, 
			  26em 71.667em #444, 
			  28em 71.667em #444;
}

.iphone-6.black.landscape::after {  
  left: -13.333em;
  top: 31.333em;
  box-shadow: inset 0 0 0 0.334em #1b1b1b,
			  5em 11.667em 0 -0.5em rgba(50, 50, 255, 0.4),
			  5.333em 11em 1em -0.5em rgba(255, 255, 255, 0.3),
			  5em 11.333em 0.333em -0.333em #222,
			  5em 11.333em 0 0 #252525,
			  5em 0 11.333em 0.333em #333, 
			  4.667em 11.667em 0 -0.833em rgba(255, 255, 255, 0.3),
			  5em 11.333em 0 0 #444,
			  5em 11.333em 0 0.333em #333, 
			  5em -5.333em 0 -0.333em #252525, 
			  5em -5em 0 -0.333em #252525, 
			  5em -4.667em 0 -0.333em #252525, 
			  5em -4.333em 0 -0.333em #252525, 
			  5em -4em 0 -0.333em #252525, 
			  5em -3.667em 0 -0.333em #252525, 
			  5em -3.333em 0 -0.333em #252525, 
			  5em -3em 0 -0.333em #252525, 
			  5em -2.667em 0 -0.333em #252525, 
			  5em -2.333em 0 -0.333em #252525, 
			  5em -2em 0 -0.333em #252525, 
			  5em -1.667em 0 -0.333em #252525, 
			  5em -1.333em 0 -0.333em #252525, 
			  5em -1em 0 -0.333em #252525, 
			  5em -0.667em 0 -0.333em #252525, 
			  5em -0.333em 0 -0.333em #252525, 
			  5em 0 0 -0.333em #252525, 
			  5em 0.333em 0 -0.333em #252525, 
			  5em 0.667em 0 -0.333em #252525, 
			  5em 1em 0 -0.333em #252525, 
			  5em 1.333em 0 -0.333em #252525, 
			  5em 1.667em 0 -0.333em #252525, 
			  5em 2em 0 -0.333em #252525, 
			  5em 2.333em 0 -0.333em #252525, 
			  5em 2.667em 0 -0.333em #252525, 
			  5em 3em 0 -0.333em #252525, 
			  5em 3.333em 0 -0.333em #252525, 
			  5em 3.667em 0 -0.333em #252525,
			  5em 4em 0 -0.333em #252525, 
			  5em 4.333em 0 -0.333em #252525, 
			  5em 4.667em 0 -0.333em #252525, 
			  5em 5em 0 -0.333em #252525, 
			  5em 5.333em 0 -0.333em #252525,
			  132em 0 2em 3em #1c1c1c, 
			  132em 0 0 4em #151515,
			  134em 0 2em 2.75em #151515,  
			  129em 0 2em 2.5em #151515,
			  132em 0 0 4.75em rgba(95, 95, 95, 0.4), 
			  135em -3em 0 3em #151515, 
			  132em -6em 0 0em #151515,
			  129em -3em 0 3em #151515, 
			  132em 0 0 4.75em #667;
}

.iphone-6.white{
  border-top: 16em solid #f8f8f8;
  border-bottom: 14.5em solid #f5f5f5;
  border-left: 2.5em solid #f8f8f8;
  border-right: 2.5em solid #f5f5f5;
  background: #e5e5e5;
  box-shadow: 0 0 0 0.667em #fcfcfc,
			  0 0 0 0.8em #d9dbdc, 
			  0 0 1.667em 0.667em rgba(252, 252, 252, 0.5), 
			  0 0 1.667em 0.667em #fcfcfc,
			  0 0 0 1.667em #d9dbdc, 
			  0 0 0 2em #bbb, 
			  0 0 5.333em 2em rgba(0, 0, 0, 0.1), 
			  inset 0 0 0 0.334em #888;
}

.iphone-6.white::before {
  background: #cbcbcb;
  box-shadow: 0 120em #cbcbcb,
			  70.667em 0 #cbcbcb, 
			  70.667em 120em #cbcbcb, 
			  -1em 7em #b0b0b0, 
			  -1em 8em #b0b0b0, 
			  -1em 10em #b0b0b0, 
			  -1em 20em #b0b0b0, 
			  -1em 21em #b0b0b0, 
			  -1em 23em #b0b0b0, 
			  -1em 24em #b0b0b0, 
			  -1em 26em #b0b0b0, 
			  -1em 28em #b0b0b0, 
			  -1em 33.333em #b0b0b0, 
			  -1em 34em #b0b0b0, 
			  -1em 36em #b0b0b0, 
			  -1em 37em #b0b0b0, 
			  -1em 39em #b0b0b0, 
			  -1em 41em #b0b0b0, 
			  71.667em 20em #b0b0b0, 
			  71.667em 21em #b0b0b0, 
			  71.667em 23em #b0b0b0, 
			  71.667em 24em #b0b0b0, 
			  71.667em 26em #b0b0b0, 
			  71.667em 28em #b0b0b0;
}

.iphone-6.white::after {
  background: #3c3d3d;
  box-shadow: -12em 5em 0 -0.667em rgba(100, 100, 255, 0.5),
			  -11em 5.333em 1em -0.5em rgba(255, 255, 255, 0.3),
			  -11.333em 5em 0 0 #3c3d3d, 
			  -11.333em 5em 0 0.333em #555, 
			  -5.333em 5em 0 -0.333em #3c3d3d, 
			  -5em 5em 0 -0.333em #3c3d3d, 
			  -4.667em 5em 0 -0.333em #3c3d3d, 
			  -4.333em 5em 0 -0.333em #3c3d3d, 
			  -4em 5em 0 -0.333em #3c3d3d, 
			  -3.667em 5em 0 -0.333em #3c3d3d, 
			  -3.333em 5em 0 -0.333em #3c3d3d, 
			  -3em 5em 0 -0.333em #3c3d3d, 
			  -2.667em 5em 0 -0.333em #3c3d3d, 
			  -2.333em 5em 0 -0.333em #3c3d3d, 
			  -2em 5em 0 -0.333em #3c3d3d, 
			  -1.667em 5em 0 -0.333em #3c3d3d, 
			  -1.333em 5em 0 -0.333em #3c3d3d, 
			  -1em 5em 0 -0.333em #3c3d3d, 
			  -0.667em 5em 0 -0.333em #3c3d3d, 
			  -0.333em 5em 0 -0.333em #3c3d3d, 
			  0 5em 0 -0.333em #3c3d3d, 
			  0.333em 5em 0 -0.333em #3c3d3d, 
			  0.667em 5em 0 -0.333em #3c3d3d, 
			  1em 5em 0 -0.333em #3c3d3d, 
			  1.333em 5em 0 -0.333em #3c3d3d, 
			  1.667em 5em 0 -0.333em #3c3d3d, 
			  2em 5em 0 -0.333em #3c3d3d, 
			  2.333em 5em 0 -0.333em #3c3d3d, 
			  2.667em 5em 0 -0.333em #3c3d3d, 
			  3em 5em 0 -0.333em #3c3d3d, 
			  3.333em 5em 0 -0.333em #3c3d3d, 
			  3.667em 5em 0 -0.333em #3c3d3d,
			  4em 5em 0 -0.333em #3c3d3d, 
			  4.333em 5em 0 -0.333em #3c3d3d, 
			  4.667em 5em 0 -0.333em #3c3d3d, 
			  5em 5em 0 -0.333em #3c3d3d, 
			  5.333em 5em 0 -0.333em #3c3d3d, 
			  -5.667em 5.333em 0 -0.333em #e0e0e0,
			  -5.333em 5.333em 0 -0.333em #e0e0e0, 
			  -5em 5.333em 0 -0.333em #e0e0e0, 
			  -4.667em 5.333em 0 -0.333em #e0e0e0, 
			  -4.333em 5.333em 0 -0.333em #e0e0e0, 
			  -4em 5.333em 0 -0.333em #e0e0e0, 
			  -3.667em 5.333em 0 -0.333em #e0e0e0, 
			  -3.333em 5.333em 0 -0.333em #e0e0e0, 
			  -3em 5.333em 0 -0.333em #e0e0e0, 
			  -2.667em 5.333em 0 -0.333em #e0e0e0, 
			  -2.333em 5.333em 0 -0.333em #e0e0e0, 
			  -2em 5.333em 0 -0.333em #e0e0e0, 
			  -1.667em 5.333em 0 -0.333em #e0e0e0, 
			  -1.333em 5.333em 0 -0.333em #e0e0e0, 
			  -1em 5.333em 0 -0.333em #e0e0e0, 
			  -0.667em 5.333em 0 -0.333em #e0e0e0, 
			  -0.333em 5.333em 0 -0.333em #e0e0e0, 
			  0 5.333em 0 -0.333em #e0e0e0, 
			  0.333em 5.333em 0 -0.333em #e0e0e0, 
			  0.667em 5.333em 0 -0.333em #e0e0e0, 
			  1em 5.333em 0 -0.333em #e0e0e0, 
			  1.333em 5.333em 0 -0.333em #e0e0e0, 
			  1.667em 5.333em 0 -0.333em #e0e0e0, 
			  2em 5.333em 0 -0.333em #e0e0e0, 
			  2.333em 5.333em 0 -0.333em #e0e0e0, 
			  2.667em 5.333em 0 -0.333em #e0e0e0, 
			  3em 5.333em 0 -0.333em #e0e0e0, 
			  3.333em 5.333em 0 -0.333em #e0e0e0, 
			  3.667em 5.333em 0 -0.333em #e0e0e0,
			  4em 5.333em 0 -0.333em #e0e0e0, 
			  4.333em 5.333em 0 -0.333em #e0e0e0,  
			  4.667em 5.333em 0 -0.333em #e0e0e0, 
			  5em 5.333em 0 -0.333em #e0e0e0,  
			  0 132em 2em 3em #f8f8f8, 
			  0 132em 0 4em #eee, 
			  0 132em 0 4.75em rgba(170, 170, 170, 0.4), 
			  3em 129em 0 3em #f5f5f5, 
			  0 126em 0 0em #f5f5f5, 
			  -3em 129em 0 3em #f5f5f5, 
			  0 132em 0 4.75em #aaaaaa, 
			  -0.333em 132.333em 0 4.667em #e0e0e0, 
			  -0.667em 132.667em 0 4.667em #f0f0f0;
}

.iphone-6.white.landscape {
  border-left: 16em solid #f8f8f8;
  border-right: 14.5em solid #f5f5f5;
  border-bottom: 2.5em solid #f5f5f5;
  border-top: 2.5em solid #f8f8f8;
  width: 111.167em;
  height: 62.5em;
}

.iphone-6.white.landscape::before {
  height: 1em;
  width: 3em;
  left: -6em;
  top: -4.333em;
  box-shadow: 120em 0 #cbcbcb,
			  0 70.667em #cbcbcb, 
			  120em 70.667em #cbcbcb, 
			  7em 71.667em #b0b0b0, 
			  8em 71.667em #b0b0b0, 
			  10em 71.667em #b0b0b0, 
			  20em -1em #b0b0b0, 
			  21em -1em #b0b0b0, 
			  23em -1em #b0b0b0, 
			  24em -1em #b0b0b0, 
			  26em -1em #b0b0b0, 
			  28em -1em #b0b0b0, 
			  33.333em 71.667em #b0b0b0, 
			  34em 71.667em #b0b0b0, 
			  36em 71.667em #b0b0b0, 
			  37em 71.667em #b0b0b0, 
			  39em 71.667em #b0b0b0, 
			  41em 71.667em #b0b0b0, 
			  20em 71.667em #b0b0b0, 
			  21em 71.667em #b0b0b0, 
			  23em 71.667em #b0b0b0, 
			  24em 71.667em #b0b0b0, 
			  26em 71.667em #b0b0b0, 
			  28em 71.667em #b0b0b0;
}

.iphone-6.white.landscape::after {  
  left: -13.333em;
  top: 31.333em;
  box-shadow: 4.667em 11.667em 0 -0.667em rgba(100, 100, 255, 0.5),
			  5.333em 11em 1em -0.5em rgba(255, 255, 255, 0.3),
			  5em 11.333em 0 0 #3c3d3d, 
			  5em 11.333em 0 0.333em #555, 
			  5em -5.333em 0 -0.333em #3c3d3d, 
			  5em -5em 0 -0.333em #3c3d3d, 
			  5em -4.667em 0 -0.333em #3c3d3d, 
			  5em -4.333em 0 -0.333em #3c3d3d, 
			  5em -4em 0 -0.333em #3c3d3d, 
			  5em -3.667em 0 -0.333em #3c3d3d, 
			  5em -3.333em 0 -0.333em #3c3d3d, 
			  5em -3em 0 -0.333em #3c3d3d, 
			  5em -2.667em 0 -0.333em #3c3d3d, 
			  5em -2.333em 0 -0.333em #3c3d3d, 
			  5em -2em 0 -0.333em #3c3d3d, 
			  5em -1.667em 0 -0.333em #3c3d3d, 
			  5em -1.333em 0 -0.333em #3c3d3d, 
			  5em -1em 0 -0.333em #3c3d3d, 
			  5em -0.667em 0 -0.333em #3c3d3d, 
			  5em -0.333em 0 -0.333em #3c3d3d, 
			  5em 0 0 -0.333em #3c3d3d, 
			  5em 0.333em 0 -0.333em #3c3d3d, 
			  5em 0.667em 0 -0.333em #3c3d3d, 
			  5em 1em 0 -0.333em #3c3d3d, 
			  5em 1.333em 0 -0.333em #3c3d3d, 
			  5em 1.667em 0 -0.333em #3c3d3d, 
			  5em 2em 0 -0.333em #3c3d3d, 
			  5em 2.333em 0 -0.333em #3c3d3d, 
			  5em 2.667em 0 -0.333em #3c3d3d, 
			  5em 3em 0 -0.333em #3c3d3d, 
			  5em 3.333em 0 -0.333em #3c3d3d, 
			  5em 3.667em 0 -0.333em #3c3d3d,
			  5em 4em 0 -0.333em #3c3d3d, 
			  5em 4.333em 0 -0.333em #3c3d3d, 
			  5em 4.667em 0 -0.333em #3c3d3d, 
			  5em 5em 0 -0.333em #3c3d3d, 
			  5em 5.333em 0 -0.333em #3c3d3d,
			  5.333em -5.333em 0 -0.333em #e0e0e0, 
			  5.333em -5em 0 -0.333em #e0e0e0, 
			  5.333em -4.667em 0 -0.333em #e0e0e0, 
			  5.333em -4.333em 0 -0.333em #e0e0e0, 
			  5.333em -4em 0 -0.333em #e0e0e0, 
			  5.333em -3.667em 0 -0.333em #e0e0e0, 
			  5.333em -3.333em 0 -0.333em #e0e0e0, 
			  5.333em -3em 0 -0.333em #e0e0e0, 
			  5.333em -2.667em 0 -0.333em #e0e0e0, 
			  5.333em -2.333em 0 -0.333em #e0e0e0, 
			  5.333em -2em 0 -0.333em #e0e0e0, 
			  5.333em -1.667em 0 -0.333em #e0e0e0, 
			  5.333em -1.333em 0 -0.333em #e0e0e0, 
			  5.333em -1em 0 -0.333em #e0e0e0, 
			  5.333em -0.667em 0 -0.333em #e0e0e0, 
			  5.333em -0.333em 0 -0.333em #e0e0e0, 
			  5.333em 0 0 -0.333em #e0e0e0, 
			  5.333em 0.333em 0 -0.333em #e0e0e0, 
			  5.333em 0.667em 0 -0.333em #e0e0e0, 
			  5.333em 1em 0 -0.333em #e0e0e0, 
			  5.333em 1.333em 0 -0.333em #e0e0e0, 
			  5.333em 1.667em 0 -0.333em #e0e0e0, 
			  5.333em 2em 0 -0.333em #e0e0e0, 
			  5.333em 2.333em 0 -0.333em #e0e0e0, 
			  5.333em 2.667em 0 -0.333em #e0e0e0, 
			  5.333em 3em 0 -0.333em #e0e0e0, 
			  5.333em 3.333em 0 -0.333em #e0e0e0, 
			  5.333em 3.667em 0 -0.333em #e0e0e0,
			  5.333em 4em 0 -0.333em #e0e0e0, 
			  5.333em 4.333em 0 -0.333em #e0e0e0,  
			  5.333em 4.667em 0 -0.333em #e0e0e0, 
			  5.333em 5em 0 -0.333em #e0e0e0, 
			  5.333em 5.333em 0 -0.333em #e0e0e0, 
			  5.333em 5.667em 0 -0.333em #e0e0e0,			  
			  132em 0 2em 3em #f8f8f8, 
			  132em 0 0 4em #eee, 
			  132em 0 0 4.75em rgba(170, 170, 170, 0.4), 
			  135em -3em 0 3em #f5f5f5, 
			  132em -6em 0 0em #f5f5f5, 
			  129em -3em 0 3em #f5f5f5, 
			  132em 0 0 4.75em #aaaaaa, 
			  131.667em 0.333em 0 4.667em #e0e0e0, 
			  131.333em 0.667em 0 4.667em #f0f0f0;
}

.mac {
  display: inline-block;
  position: relative;
  font-size: 6px;
  height: 120em;
  width: 213.334em;
  margin-bottom: 28em;
  box-sizing: content-box;
  border-radius: 5.67em;
  padding: 9.5em 9.5em 8.84em 9.5em;
  box-shadow: inset 9.5em 0 #111, inset -9.5em 0 #111, inset 0 9.5em #111, inset 0 -8.84em #111;
  border-bottom: 21em solid transparent;
  perspective: 8.34em;
  -webkit-perspective: 8.34em;
  background: linear-gradient(45deg, #898a8c, #e1e2e4);
}

.mac::before {
  content: '';
  position: absolute;
  top: calc(100% + 21em);
  left: calc(50% - 60em);
  width: 120em;
  height: 40em;
  background:   radial-gradient(farthest-side at right top, #7e7e7f, transparent) bottom 3em left 13em,
				radial-gradient(farthest-side at left top, #7e7e7f, transparent) bottom 3em right 13em,
				radial-gradient(farthest-side at center bottom, #a1a1a3, transparent) bottom 1em left 15em, 
				radial-gradient(farthest-side at center bottom, #a1a1a3, transparent) bottom 1em left 6em,
				radial-gradient(farthest-side at center bottom, #a1a1a3, transparent) bottom 1em right 6em,
				linear-gradient(0deg, #e7e7e9 5%, #79797b 11%, #828280 17%, #9e9ea0 20%, #f1f1f1 30%, #eaeaea 40%, #77787c 100%) top left 17em, 
				linear-gradient(#e7e7e9, #e7e7e9) bottom 1em left 14em,  
				linear-gradient(-15deg, #e7e7e9 48%, transparent 52%) bottom 1em left, 
				linear-gradient(15deg, #e7e7e9 48%, transparent 52%) bottom 1em right, 
				linear-gradient(45deg, #9b9b9d 0%, #ececee 10%, #ececee 90%, #9b9b9d 100%) bottom left, 
				radial-gradient(farthest-side at left top, transparent 48%, #7e7e7f 50%) top 23em left 14em, 
				radial-gradient(farthest-side at right top, transparent 48%, #7e7e7f 50%) top 23em right 14em;
  background-size: 4em 2em,
				   4em 2em,
				   90em 10em, 
				   90em 4em,
				   90em 4em, 
				   86em 39em, 
				   92em 4em, 
				   14em 4em, 
				   14em 4em, 
				   100% 1em, 
				   7em 24em, 
				   7em 24em;
  border-bottom-left-radius: 23.34em 0.34em;
  border-bottom-right-radius: 23.34em 0.34em;
  background-repeat: no-repeat;
  transform-origin: top;
  transform: rotateX(3deg) scale3d(0.54, 0.4, 1);
  -webkit-transform-origin: top;
  -webkit-transform: rotateX(3deg) scale3d(0.54, 0.4, 1);
  box-shadow: 0 2.3em 1.34em -2em, 0 2em 1.34em -2em;
}

.mac::after {
  content: '';
  position: absolute;
  top: 130.8em;
  left: 103.17em;
  transform: scale(0.19);
  -webkit-transform: scale(0.19);
  width: 25.17em;
  height: 25.17em;
  background: #111;
  background-clip: content-box;
  box-sizing: border-box;
  padding: 1.67em;
  border-radius: 166.67em;
  box-shadow: 1.67em -738.34em 8.34em -8.34em #111,
              1.67em -734.84em 0 -10em #474956,
			  1.67em -734.84em 0 -8.34em #1b1c26,
			  1.67em -734.84em 0 -6.67em #36373a,
			  inset 14.17em 11.34em #c4c5c7,  
			  30em 28em 0 #c4c5c7, 
			  13em 24em 0 #111, 
			  13em 36.17em 0 #111, 
			  12em 38.5em 0 #111, 
			  10.5em 41em 0 #111, 
			  11em 46.67em 0 -5.17em #111, 
			  12.17em 47em 0 -5.17em #111, 
			  13.34em 45.84em 0 -5.17em #111, 
			  6em 45.17em 0 -5.17em #111, 
			  2.84em 44.84em 0 -5.17em #111, 
			  -1.5em 45.5em 0 -5.17em #111, 
			  -3.5em 46.17em 0 -5.17em #111, 
			  -6.17em 47.17em 0 -5.17em #111, 
			  -4em 33em 0 3.84em #111, 
			  -4.17em 35.67em 0 2.84em #111, 
			  -3.84em 40.34em 0 #111, 
			  -5.34em 39.67em 0 #111, 
			  -5.17em 42.5em 0 -1.34em #111, 
			  -5.67em 44.34em 0 -2.84em #111, 
			  1.34em 42.67em 0 -2.84em #111, 
			  4.5em 42.67em 0 -2.84em #111, 
			  -9.84em 32.34em 0 -1.67em #111, 
			  -10.17em 30.17em 0 -1.67em #111, 
			  -10.17em 27.84em 0 -1.67em #111, 
			  -9.84em 25.84em 0 -1.67em #111, 
			  -9.17em 24em 0 -1.67em #111, 
			  -8.17em 22.67em 0 -1.67em #111, 
			  -6.67em 22.84em 0 -1.67em #111, 
			  -4.34em 23.5em 0 -1.67em #111, 
			  -1.34em 24.34em 0 -1.67em #111, 
			  7.17em 24em 0 -1.67em #111, 
			  10.34em 23em 0 -1.67em #111, 
			  3em 24.67em 0 -1.67em #111;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.mac::after {
	  background: transparent;
	  box-shadow: 1.67em -738.34em 8.34em -8.34em #111,
				  1.67em -734.84em 0 -10em #474956,
				  1.67em -734.84em 0 -8.34em #1b1c26,
				  1.67em -734.84em 0 -6.67em #36373a,
				  inset 14.17em 11.34em #c5c6c8, 
				  inset -64em -64.17em 0 -80.5em #111, 
				  30em 28em 0.67em #c5c6c8, 
				  13em 24em 0.67em #111, 
				  13em 36.17em 0.67em #111, 
				  12em 38.5em 0.67em #111, 
				  10.5em 41em 0.67em #111, 
				  11em 46.67em 1.17em -5.17em #111, 
				  12.17em 47em 1.17em -5.17em #111, 
				  13.34em 45.84em 1.17em -5.17em #111, 
				  6em 45.17em 1.17em -5.17em #111, 
				  2.84em 44.84em 1.17em -5.17em #111, 
				  -1.5em 45.5em 1.17em -5.17em #111, 
				  -3.5em 46.17em 1.17em -5.17em #111, 
				  -6.17em 47.17em 1.17em -5.17em #111, 
				  -4em 33em 1.17em 3.84em #111, 
				  -4.17em 35.67em 1.17em 2.84em #111, 
				  -3.84em 40.34em 0.67em #111, 
				  -5.34em 39.67em 0.67em #111, 
				  -5.17em 42.5em 1.17em -1.34em #111, 
				  -5.67em 44.34em 1.17em -2.84em #111, 
				  1.34em 42.67em 1.17em -2.84em #111, 
				  4.5em 42.67em 1.17em -2.84em #111, 
				  -9.84em 32.34em 1.17em -1.67em #111, 
				  -10.17em 30.17em 1.17em -1.67em #111, 
				  -10.17em 27.84em 1.17em -1.67em #111, 
				  -9.84em 25.84em 1.17em -1.67em #111, 
				  -9.17em 24em 1.17em -1.67em #111, 
				  -8.17em 22.67em 1.17em -1.67em #111, 
				  -6.67em 22.84em 1.17em -1.67em #111, 
				  -4.34em 23.5em 1.17em -1.67em #111, 
				  -1.34em 24.34em 1.17em -1.67em #111, 
				  7.17em 24em 1.17em -1.67em #111, 
				  10.34em 23em 1.17em -1.67em #111, 
				  3em 24.67em 1.17em -1.67em #111;
	}
}

.ipad-mini-3 {
  display: inline-block;
  position: relative;
  overflow: hidden;
  box-sizing: content-box;
  width: 128em;
  height: 170.667em;
  font-size: 6px;
  border-radius: 11em;
  padding: 20.667em 7em;
  border: 1em solid transparent;
  background:	linear-gradient(45deg, transparent 38%, #f5f6f8 49%, transparent 50%) top -8em left -1em,
				linear-gradient(-45deg, transparent 49%, #f5f6f8 50%, transparent 62%) top -4em right -1em,
				linear-gradient(-45deg, transparent 49%, #f5f6f8 50%, transparent 62%) bottom -8em left -1em,
				linear-gradient(45deg, transparent 38%, #f5f6f8 49%, transparent 50%) bottom -6em right -1em,
				radial-gradient(farthest-side at left top, #47474d, transparent) top -1em left -1em,
				radial-gradient(farthest-side at left bottom, #47474d, transparent) bottom -1em left -1em,
				radial-gradient(farthest-side at right bottom, #47474d, transparent) bottom -1em right -1em,
				radial-gradient(farthest-side at right top, #47474d, transparent) top -1em right -1em;
  background-size: 20% 20%;
  background-color: currentColor;
  background-repeat: no-repeat;
}

.ipad-mini-3::after {
  content: '';
  position: absolute;
  width: 10em;
  height: 10em;
  border-radius: 100%;
  top: 198em;
  left: calc(50% - 5em);
}

.ipad-mini-3.white {  
  color: #d4d7d6;
  box-shadow:   inset 0 0 0 0.5em white,
				inset 0 219.667em 0 -200em #f5f6f8, 
				inset 0 -219.667em 0 -200em #f5f6f8, 
				inset 206em 0 0 -200em #f5f6f8, 
				inset -206em 0 0 -200em #f5f6f8, 
				inset -207em 0 0 -200em #1d1d1e, 
				inset 207em 0 0 -200em #1d1d1e, 
				inset 0 -220.667em 0 -200em #1d1d1e, 
				inset 0 220.667em 0 -200em #1d1d1e,
				inset 0 0 0 100em #dee2e1, 
				0 0 5em rgba(0, 0, 0, 0.07);
}

.ipad-mini-3.white::after {  
  box-shadow: 	5em 2em 2em -2em rgba(245, 246, 248, 0.8), 
				4em 0 4em -1em rgba(245, 246, 248, 0.8), 
				-3em 1em 1em -2em rgba(245, 246, 248, 0.6), 
				-3em 2em 2em -1em rgba(245, 246, 248, 0.8), 
				0 0 0 0.667em #404143,
				0.2em -192.5em 0 -4.5em #04449e, 
				0 -192.5em 0 -4.1em #11356f, 
				2em -192em 4em -3.5em #f5f6f8, 
				0 -192.5em 0 -3.5em #352e28, 
				-4.5em -192.5em 0 -4em #e9eaec;
}

.ipad-mini-3.black {
  color: #a3a3a3;
  box-shadow:   inset 0 0 0 0.5em #888b91,
				inset 0 219.667em 0 -200em #111, 
				inset 0 -219.667em 0 -200em #111, 
				inset 206em 0 0 -200em #111, 
				inset -206em 0 0 -200em #111, 
				inset -207em 0 0 -200em #1d1d1e, 
				inset 207em 0 0 -200em #1d1d1e, 
				inset 0 -220.667em 0 -200em #1d1d1e, 
				inset 0 220.667em 0 -200em #1d1d1e,
				inset 0 0 0 100em #dee2e1, 
				0 0 5em rgba(0, 0, 0, 0.07);
}

.ipad-mini-3.black::after {
  box-shadow: 	397em -230em 0 400em rgba(255, 255, 255, 0.05), 
				390em -200em 100em 300em rgba(255, 255, 255, 0.7), 
				5em 2em 2em -2em rgba(17, 17, 17, 0.8), 
				4em 0 4em -1em
}