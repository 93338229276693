*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
  font-family: Futura !important;
  font-size: 1.8rem !important;
  color: var(--main-font-color-dark) !important;
  font-weight: 400 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  margin: 1.5rem;
}

:root {
  --main-font: 'Futura', sans-serif;
  --secondary-font:'Shellana';
  --body-font: "Futura",sans-serif;
  --main-font-color-dark: #342714;
  --secondary-font-color: #c39d63;
  --body-font-color: #342714;
  --main-font-color-error: #7F1019;
  --base-duration: 1.5s;
}